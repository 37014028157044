import { useEffect, useState, createRef, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkMyAuction, getTopTenBidders, loadAuctionById, removeAuctionById, resetTopTenBidderLoading, resetTopTenBidders, setNewBidder } from '../../features/auctionsSlice';
import { addWatchList, loadAllWatchList, removeWatchList } from '../../features/watchListSlice';
import { getMySubscriptions, loadBanks, toggleBidSecurityModal, toggleAddReceiptModal, toggleBidSubmitModal, toggleOneTimeModal } from '../../features/bidSlice';
import { watch_icon, unwatch_icon, sealed_icon, pdf_icon, top_bidder_cup_2, winner_cup, hit_gavel, zig_chereta, image_placeholder, map } from '../../assets';
import { BsFullscreen, BsArrowLeft, BsXLg, BsCaretRightFill } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { MdOutlineContentCopy } from "react-icons/md";
import { FiRefreshCw, FiX } from "react-icons/fi";
import { CountdownTimer, isAuctionAboutToEnd, isInMyBids, opt } from '../../utils';
import AuthService from '../../Services/AuthService';
import ImageGallery from 'react-image-gallery';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import Mapping from '../maps/Mapping';
import Loading from '../common/Loading';
import AnimateTopBidders from '../monitoringScreen/AnimateTopBidders';
import BidderBar from '../monitoringScreen/BidderBar';
import translate from '../../Services/translate';
import Modal from '../common/Modal';
import myModel from '../../Services/api';
import { getRequiredDocumentsList, toggleDocumentNeededModal, toggleUploadDocumentModal } from '../../features/documentsSlice';

import { ImEyePlus } from "react-icons/im";
import { ImEyeMinus } from "react-icons/im";
import { toggle_compliance_success_modal, toggleComplianceModal } from '../../features/chatSlice';

const AuctionDetail = () => {

   const { id } = useParams();
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { token } = useSelector(({ auth }) => auth);
   const { language } = useSelector(({ language }) => language);
   const { allWatchList, watchlist_status, watchlist_loading } = useSelector(({ watchList }) => watchList);
   const { bid_loading, myBidsLight, is_subscription_started, my_subscriptions, subscriptionCheck_status, subscription_loading, show_add_receipt_modal } = useSelector(({ bid }) => bid);
   const { auctionById, auctionById_loading, newBidder, updatedBidId, nowUtcTime, topTenBidders_loading } = useSelector(({ auction }) => auction);
   const { show_compliance_modal } = useSelector(({ chat }) => chat);
   const [compliance, setCompliance] = useState({
      auction: id,
      message: ""
   })


   const { requiredList, documents } = useSelector(({ document }) => document);
   const [selectedAuct, setSelectedAuct] = useState(0);

   const { topTenBidders, detail } = auctionById || {};
   const [topBidders, setTopBidders] = useState(topTenBidders);
   const defTopTen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   const center = { lat: 8.999724, lng: 38.784886 };
   const [detailStatuses, setDetailStatuses] = useState({
      currentPrice: null,
      myBidValue: null,
      isActive: false,
      startedBidding: false,
      isTopBidder: false,
      isAuctionEndingSoon: false,
      processing: false,
      hitGavel: false,
      ended: false
   });
   const [showNotifierPopup, setShowNotifierPopup] = useState(false);
   const [missedTopBidders, setMissedTopBidders] = useState([]);
   const [showLocation, setShowLocation] = useState(false);
   const [showMapTooltip, setShowMapTooltip] = useState(false);
   const [copiedMap, setCopiedMap] = useState({});
   const [missingDocuments, setmissingDocuments] = useState(null);
   const { currentPrice, myBidValue, isActive, startedBidding, isTopBidder, isAuctionEndingSoon, processing, hitGavel, ended,
   } = detailStatuses


   const memoizedBidderBars = useMemo(() => (
      topBidders.map(({ user_id, first_name, last_name, amount }, idx) => (
         <BidderBar
            idx={idx}
            key={user_id}
            id={user_id}
            first_name={first_name}
            last_name={last_name}
            amount={amount}
            ref={createRef()}
         />
      ))
   ), [topBidders]);

   const images = detail?.product?.images?.map(url => ({
      original: `${url}`,
      thumbnail: `${url}`,
   }))

   const canSeeTopBidder = () => {
      if (is_subscription_started) {
         return token && subscriptionCheck_status
      } else {
         return true
      }
   }

   const addToWatchList = id => {
      dispatch(addWatchList({ id })).then(() => {
         if (watchlist_status === 'success') {
            dispatch(loadAllWatchList()).then(() => {
               if (watchlist_status === 'success' && !watchlist_loading)
                  toast.success(translate(1), { duration: 3000 })
            })
         }
      })
   }

   const removeFromWatchList = id => {
      dispatch(removeWatchList({ id })).then(() => {
         if (watchlist_status === 'success' && !watchlist_loading) {
            dispatch(loadAllWatchList()).then(() => {
               if (watchlist_status === 'success' && !watchlist_loading)
                  toast.success(translate(2), { duration: 3000 })
            })
         }
      })
   }

   const isWatched = id => {
      if (allWatchList.length) {
         const index = allWatchList.findIndex(watchList => watchList.id === id)
         if (index >= 0)
            return true
         return false
      }
      return false
   }

   const handleWatchClick = id => {
      if (AuthService.getToken()) {
         if (!isWatched(id)) {
            addToWatchList(id)
         } else {
            removeFromWatchList(id)
         }
      } else {
         navigate('/login')
      }
   }
   const [subStatus, setsubStatus] = useState(null)



   const checkMySubscriptions = async (auction_id) => {
      const response = await myModel.find(
         ``,
         "subscription-check?auction_id=" + auction_id
      );
      return response;
   };


   const compareDocuments = (requiredDocuments, userDocuments) => {
      const userDocumentIds = userDocuments?.map(doc => doc?.required_document?.id);
      const missingDocuments = requiredDocuments?.filter(
         requiredDoc => !userDocumentIds?.includes(requiredDoc?.id)
      );
      setmissingDocuments(missingDocuments)
      return {
         missingDocuments,
      };
   };
   useEffect(() => {
      token && auctionById?.detail?.required_documents && compareDocuments(auctionById?.detail?.required_documents, documents)
      console.log("missingDocuments", missingDocuments)
   }, [requiredList, documents, auctionById])
   //  console.log("mm:",documents)
   //  console.log("mmvvvvv:",required_documents)

   const checkSubscription = () => {
      id && checkMySubscriptions(id).then((response) => {
         console.log("checked", response)
         if (response) {
            setsubStatus(response)

         }
      })
   }
   useEffect(() => {
      token && checkSubscription()
      console.log("kkkkkkkkkkkkkkkkkkkkk")
   }, [subscription_loading, show_add_receipt_modal, token])


   const goToBidSubmission = (id, auction) => {
      console.log("bbb", id)
      if (token) {
         id && setSelectedAuct(id);
         id && checkMySubscriptions(id).then((data) => {
            checkMyAuction(id).then((auc_data) => {
               console.log("ssssssssssss", auc_data)
               if (auc_data.one_time_subscription && !data.user_has_subscription) {
                  dispatch(toggleAddReceiptModal({ value: true }));
               }



               else if (data.require_subscription && data.user_has_subscription) {
                  if (auc_data.can_bid) {
                     dispatch(loadAuctionById({ id })).then(() => {
                        dispatch(toggleBidSubmitModal({ value: true }));
                     });
                  } else {
                     goToBidSecurity()
                  }
               } else if (!data.require_subscription) {
                  if (auc_data.can_bid) {
                     dispatch(loadAuctionById({ id })).then(() => {
                        dispatch(toggleBidSubmitModal({ value: true }));
                     });
                  } else {
                     goToBidSecurity()
                  }
               } else if (is_subscription_started) {
                  if (
                     my_subscriptions.length &&
                     my_subscriptions?.some((pack) => pack.is_active === true)
                  ) {
                     if (auc_data.can_bid) {
                        dispatch(loadAuctionById({ id })).then(() => {
                           dispatch(toggleBidSubmitModal({ value: true }));
                        });
                     } else {
                        goToBidSecurity()
                     }
                  } else {
                     navigate("/subscription");
                  }
               } else {
                  if (auc_data.can_bid) {
                     dispatch(loadAuctionById({ id })).then(() => {
                        dispatch(toggleBidSubmitModal({ value: true }));
                     });
                  } else {
                     goToBidSecurity()
                  }
               }
            });
         });
      }
      else {
         navigate("/login", { state: { from: location } });
      }
   };

   const goToBidSecurity = () => {
      dispatch(loadBanks()).then(() => {
         dispatch(toggleBidSecurityModal({ value: true }))
      })
   }
   const goToAddRecipt = () => {
      dispatch(loadBanks()).then(() => {
         dispatch(toggleAddReceiptModal({ value: id }))
      })
   }

   const copyToClipboard = (text, key) => {
      navigator.clipboard.writeText(text);
      setCopiedMap(prevMap => ({
         ...prevMap,
         [key]: true
      }));
      console.log('Copied map ==>', copiedMap)
      setTimeout(() => {
         setCopiedMap(prevMap => ({
            ...prevMap,
            [key]: false
         }));
      }, 2000);
   };

   useEffect(() => {
      if (detail?.product) {
         setDetailStatuses((prevStatuses) => {
            const currentPrice = detail?.current_price
            const myBidValue = detail?.my_bid_value
            const { startedBidding, isTopBidder, isActive } = isInMyBids(detail, myBidsLight)
            const isAuctionEndingSoon = isAuctionAboutToEnd(detail.end_time, nowUtcTime)
            const processing = new Date(detail.end_time).getTime() - nowUtcTime < 0 && nowUtcTime < (new Date(detail.end_time).getTime() + 2500)
            const hitGavel = (new Date(detail.end_time).getTime() + 2500) - nowUtcTime < 0 && nowUtcTime - (new Date(detail.end_time).getTime() + 2500) <= 5200
            const ended = new Date(detail.end_time).getTime() + 2500 + 5200 < nowUtcTime

            return {
               currentPrice,
               myBidValue,
               isActive,
               startedBidding,
               isTopBidder,
               isAuctionEndingSoon,
               processing,
               hitGavel,
               ended
            }
         })
      }
      // eslint-disable-next-line
   }, [nowUtcTime, detail, myBidsLight]);

   useEffect(() => {
      dispatch(removeAuctionById())
      dispatch(loadAuctionById({ id }))
      dispatch(resetTopTenBidders())
      dispatch(getTopTenBidders({ id }))
      if (token) {
         dispatch(loadAllWatchList())
         dispatch(getMySubscriptions())
      }
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (topBidders.length) {
         let arr = [];
         for (let i = 1; i <= 10 - topBidders.length; i++) {
            arr.push({ num: topBidders.length + i })
         }
         setMissedTopBidders(arr)
      }
   }, [topBidders]);

   useEffect(() => {
      const timer = setTimeout(() => {
         let list = [...topTenBidders]
         const newAnimatedTopBidders = list.sort((a, b) => b.amount - a.amount).slice(0, 10)
         setTopBidders(newAnimatedTopBidders);
         dispatch(resetTopTenBidderLoading())
      }, 100);

      return () => clearTimeout(timer)
      // eslint-disable-next-line
   }, [topTenBidders]);

   useEffect(() => {
      let timer;
      if (newBidder) {
         setShowNotifierPopup(true)
         timer = setTimeout(() => {
            dispatch(setNewBidder(false))
            setShowNotifierPopup(false)
         }, 4000);
      }
      return () => clearTimeout(timer)
      // eslint-disable-next-line
   }, [newBidder]);
   const openComplianceModal = () => {
      if (token) {
         dispatch(toggleComplianceModal({ value: true }))
      } else {
         navigate("/login", { state: { from: location } });
      }
   }
   const SubmitCompliance = async () => {


      try {
         const response = await myModel.create('/submit-compliance', '', compliance)
         console.log("kkkllll", response)
         if (response) {
            dispatch(toggleComplianceModal({ value: false }))
            dispatch(toggle_compliance_success_modal({ value: true }));

         }
         return response
      } catch (error) {
         console.log(error)
         // return rejectWithValue(error)
      }


   };

   return (
      <>
         {detail && detailStatuses ?
            <div className='px-1 pb-3 pt-1'>
               <div className='bg-gradient-to-b from-gray-50 to-gray-300 w-full md:shadow-md shadow-stone-400 flex md:justify-center border-b border-gray-300 relative'>
                  <div className='pb-2 pl-2 w-[25%] hidden lg:block'>
                     <div className='flex flex-col justify-start items-end h-full w-full'>
                        <div className='w-full h-full flex flex-col justify-between px-2'>
                           <div className='w-full pt-2'>
                              <div className='flex justify-between items-center'>
                                 <p className='text-gray-700'>{translate(3)}</p>
                                 <div className={`text-lime-600 font-semibold ${!detail?.is_closed ? 'text-2xl' : 'text-base'}`}>
                                    {!detail?.is_closed && currentPrice ?
                                       <>
                                          {canSeeTopBidder() ?
                                             <div className='text-2xl font-Roboto'>{currentPrice.toLocaleString('en', opt)}
                                                <span className='text-sm'> {translate(4)}</span>
                                             </div>
                                             :
                                             <div
                                                title='Click to go to Subscription page'
                                                className='text-xl text-sky-600 hover:text-sky-800 cursor-pointer'
                                                onClick={() => navigate('/subscription')}
                                             >
                                                {translate(343)}
                                             </div>
                                          }
                                       </>
                                       :
                                       <div className='text-lg'>{`**${translate(5)}**`}</div>
                                    }
                                 </div>
                              </div>

                              <div className='flex justify-between items-center'>
                                 <p className='text-gray-700'>{translate(6)}</p>
                                 {token ?
                                    <>
                                       {myBidValue ?
                                          <div className='text-sky-500 text-lg pr-3 font-Roboto'>{myBidValue.toLocaleString('en', opt)}
                                             <span className='text-sm'> {translate(4)}</span>
                                          </div>
                                          :
                                          <div className='text-sky-600 text-lg pr-3'>---</div>
                                       }
                                    </>
                                    :
                                    <p className='text-gray-600'>{translate(342)}</p>
                                 }
                              </div>

                              <div className='flex justify-between items-center'>
                                 <p className={`text-gray-700`}>{translate(7)}</p>
                                 {token ?
                                    <p className={`${isActive ? 'text-lime-600 font-semibold' : 'text-red-600'}`}>
                                       {isActive ? translate(8) : translate(9)}
                                    </p>
                                    :
                                    <p className='text-gray-600'>{translate(342)}</p>
                                 }
                              </div>
                           </div>

                           {isActive ?
                              <div className='flex justify-center'>
                                 <div className='text-gray-600 text-sm'>{translate(10)}</div>
                                 <GiCheckMark className=' text-lg ml-2 text-lime-600' />
                              </div>
                              :
                              null
                           }

                        </div>
                     </div>
                  </div>

                  <div className='w-full sm:w-full lg:w-[60%] h-full border-x sm:border-gray-400 border-gray-200 relative'>
                     <div className='w-full md:flex justify-between'>
                        <div className='text-2xl md:text-3xl text-left pl-3 font-Roboto'>{detail?.product?.name}</div>
                        {isTopBidder && !detail?.is_closed && token ?
                           <div className={`flex justify-around rounded-lg mt-1 mr-1 bg-lime-600 px-2 ${ended ? 'top-bidder' : ''}`}>
                              <div className='text-base sm:text-2xl text-center text-white sm:py-2 flex items-center justify-center pr-3'>
                                 {ended ? translate(13) : translate(12)}
                              </div>
                              <div className='flex items-center py-1'>
                                 <img src={ended ? winner_cup : top_bidder_cup_2} alt='' className='h-7 sm:h-12' />
                              </div>
                           </div>
                           :
                           null
                        }
                     </div>

                     {currentPrice && !detail?.is_closed ?
                        <div className={`absolute top-0 right-0 left-0 h-14 flex items-center justify-center ${showNotifierPopup && (updatedBidId === detail?.id) ? 'show-notifier-popup' : 'hide-notifier-popup'}`}>
                           <div className='flex text-white h-full rounded-lg border border-black w-[90%] justify-center items-center notifier-popup  px-3 bg-sky-800'>
                              <div>
                                 {translate(266)}
                                 <span className='text-lg font-bold'> {currentPrice && currentPrice?.toLocaleString('en', opt)} </span>
                                 {translate(267)}
                              </div>
                           </div>
                        </div>
                        :
                        null
                     }

                     {detail?.is_closed ?
                        <div className='flex items-center justify-center font-semibold gap-3'>
                           <img src={language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon} alt="" className='h-10 w-10' />
                           <div className='text-red-400 text-lg'>{translate(14)}</div>
                        </div>
                        :
                        null
                     }

                     <div className='hidden sm:block'>
                        <div className='w-full flex justify-between border-b border-gray-400'>
                           {!detail?.is_closed &&
                              <>
                                 <div className='pl-2'>
                                    <div className='text-sm pt-5'>{translate(3)}</div>
                                    {canSeeTopBidder() ?
                                       <div className='font-semibold text-xl pl-2 md:pl-5 font-Roboto'>{currentPrice ? currentPrice.toLocaleString('en', opt) : '-'}
                                          <span className='text-base font-normal'> {translate(4)}</span>
                                       </div>
                                       :
                                       <div
                                          title='Click to go to Subscription page'
                                          className='text-xl pl-2 md:pl-5 text-sky-600 hover:text-sky-800  cursor-pointer'
                                          onClick={() => navigate('/subscription')}
                                       >
                                          {translate(343)}
                                       </div>
                                    }
                                 </div>

                                 <div className='pr-2'>
                                    <div className='text-sm pt-5'>{translate(15)}</div>
                                    <div className='font-semibold text-xl pl-2 md:pl-5 font-Roboto'>{detail?.min_increment ? detail.min_increment.toLocaleString('en', opt) : '-'}
                                       <span className='text-base font-normal'> {translate(4)}</span>
                                    </div>
                                 </div>

                                 <div className='pr-2'>
                                    <div className='text-sm pt-5'>{translate(16)}</div>
                                    <div className={`${language === 'Amharic' && !detail?.max_increment ? 'font-normal' : 'font-semibold font-Roboto'} text-xl pl-2 md:pl-5`}>{detail?.max_increment ? detail.max_increment.toLocaleString('en', opt) : translate(81)}
                                       {detail?.max_increment ? <span className='text-base font-normal'> {translate(4)}</span> : null}
                                    </div>
                                 </div>
                              </>
                           }
                           <div className='pl-2'>
                              <div className='text-sm pt-5'>{translate(17)}</div>
                              <div className='font-semibold text-xl pl-2 font-Roboto'>{detail?.no_of_bids ? detail.no_of_bids : 0}</div>
                           </div>
                           <div className='pr-2'>
                              <div className='text-sm pt-5'>{translate(18)}</div>
                              <div className='font-semibold text-xl font-Roboto'>{detail?.lot_number ? detail.lot_number : '-'}</div>
                           </div>
                        </div>
                     </div>

                     <div className='px-3 pt-3 block sm:hidden border-b border-gray-400'>
                        {!detail?.is_closed ?
                           <>
                              <div className='flex justify-between'>
                                 <div className='text-sm'>{translate(3)}</div>
                                 {canSeeTopBidder() ?
                                    <div className='font-semibold pl-2 font-Roboto'>{currentPrice ? currentPrice.toLocaleString('en', opt) : '-'}
                                       <span className='text-sm font-normal'> {translate(4)}</span>
                                    </div>
                                    :
                                    <div
                                       title='Click to go to Subscription page'
                                       className='font-semibold text-sm pl-2 text-sky-800 hover:text-sky-600 cursor-pointer'
                                       onClick={() => navigate('/subscription')}
                                    >
                                       {translate(343)}
                                    </div>
                                 }
                              </div>
                              <div className='flex justify-between'>
                                 <div className='text-sm'>{translate(15)}</div>
                                 <div className='font-semibold pl-2 font-Roboto'>{detail?.min_increment ? detail.min_increment.toLocaleString('en', opt) : '-'}
                                    <span className='text-xs font-normal'> {translate(4)}</span>
                                 </div>
                              </div>
                              <div className='flex justify-between'>
                                 <div className='text-sm'>{translate(16)}</div>
                                 <div className={`${language === 'Amharic' && !detail?.max_increment ? 'font-normal' : 'font-semibold font-Roboto'} pl-2`}>
                                    {detail?.max_increment ? detail.max_increment.toLocaleString('en', opt) : translate(81)}
                                    {detail?.max_increment ? <span className='text-xs font-normal'> {translate(4)}</span> : null}
                                 </div>
                              </div>
                           </>
                           :
                           null
                        }
                        <div className='flex justify-between'>
                           <div className='text-sm'>{translate(17)}</div>
                           <div className='font-semibold pl-2 font-Roboto'>{detail?.no_of_bids ? detail.no_of_bids : '0'}</div>
                        </div>
                        <div className='flex justify-between'>
                           <div className='text-sm'>{translate(18)}</div>
                           <div className='font-semibold font-Roboto'>{detail?.lot_number ? detail.lot_number : '-'}</div>
                        </div>
                     </div>

                     <div className='lg:flex w-full'>
                        <div className='flex lg:w-[60%] border-b border-gray-400'>
                           <div className='w-2/5 pl-2 border-r border-gray-400'>
                              {/* {!detail?.is_closed ?
                                 <> */}
                              <div className='text-xs'>{translate(19)}</div>
                              <div className='text-base sm:text-lg font-semibold text-center font-Roboto'>
                                 {detail?.initial_price ? detail.initial_price.toLocaleString('en', opt) : '-'}
                                 <span className='text-sm font-normal'> {translate(4)}</span>
                              </div>
                              {/* </>
                                 :
                                 null
                              } */}
                           </div>
                           <div className='w-3/5 pl-2 lg:border-r border-gray-400'>
                              <p className='text-xs'>{translate(20)}</p>
                              <div className='text-center font-Roboto'>
                                 <CountdownTimer timestampMs={new Date(detail?.end_time).getTime()} view='grid' />
                              </div>
                           </div>
                        </div>

                        <div className='flex w-full lg:w-[40%] border-b border-gray-400'>
                           <div className=' border-r border-gray-400 w-[58%] flex-1'>
                              <p className='text-xs text-left pl-2'>{translate(25)}</p>
                              <p className='text-base sm:text-lg font-semibold text-center font-Roboto'>{dayjs(new Date(detail?.end_time).toISOString()).format('h:mm a, MMM D, YYYY')}</p>
                           </div>

                           <div className='border-r border-gray-400 flex justify-center items-center px-1 w-[12%] hover:bg-gray-100 cursor-pointer' onClick={() => handleWatchClick(detail?.id)}>
                              {watchlist_loading ?
                                 <div className='flex justify-center'>
                                    <Loading loading={watchlist_loading} loadingStyle='text-sky-600 w-5 h-5' />
                                 </div>
                                 :
                                 token && isWatched(detail?.id) ? <ImEyeMinus size={28} /> : <ImEyePlus size={28} />


                              }
                           </div>
                           {/* <div className = 'flex justify-end items-center w-[30%] p-1'>
                              {!startedBidding && !ended ?
                                 <button 
                                    onClick = {()=>goToBidSubmission(id) } 
                                    className = 'bg-primary-100 hover:bg-lime-600 rounded text-white w-full h-full' 
                                 >
                                    {token?subStatus?subStatus?.require_subscription && !subStatus?.user_has_subscription && !subStatus?.subscription_pending_approval?"Pay Subscription":subStatus?.require_subscription && subStatus?.subscription_pending_approval?"Approval pending (pls wait...)":translate(28): <Loading loading = { bid_loading } loadingStyle = 'text-sky-600 w-5 h-5' />:translate(28) }
                                 </button>
                                 :
                                 null
                              }
                           </div> */}
                        </div>
                     </div>

                     {isAuctionEndingSoon ?
                        <div
                           title={translate(29)}
                           className={`blob absolute right-2 top-2`}>
                        </div>
                        :
                        null
                     }

                  </div>

                  <div className=' w-[15%] hidden lg:block'>
                     <div className='flex flex-col justify-end h-full w-full pb-2 px-1'>

                        <div className='flex justify-center'>


                           <button
                              onClick={() => openComplianceModal()}
                              // className = 'bg-primary-100 hover:bg-lime-600 rounded text-white w-full h-full' 
                              className={`flex justify-center gap-3 border-b-2 border-white text-white  bg-primary-100  hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px]`}
                           >
                              Add Compliance
                           </button>

                        </div>
                        <div className='flex justify-center'>

                           {!startedBidding && !ended ?
                              <button
                                 onClick={() => goToBidSubmission(id)}
                                 // className = 'bg-primary-100 hover:bg-lime-600 rounded text-white w-full h-full' 
                                 className={`flex justify-center gap-3 border-b-2 border-white text-white  bg-primary-100  hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px]`}
                              >
                                 {token ? subStatus ? subStatus?.require_subscription && !subStatus?.user_has_subscription && !subStatus?.subscription_pending_approval ? "Pay Subscription" : subStatus?.require_subscription && subStatus?.subscription_pending_approval ? "Approval pending (pls wait...)" : translate(28) : <Loading loading={bid_loading} loadingStyle='text-sky-600 w-5 h-5' /> : translate(28)}
                              </button>
                              :
                              null
                           }
                           {/* ttttttttttttttttttttttttt */}
                           {/* <button 
                              className = { `flex justify-center gap-3 border-b-2 border-white text-white bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px]`  }
                              onClick = { () => token ? navigate(`/monitor/${id}`) : navigate('/login') }
                           >
                              <BsFullscreen className = 'h-5 pt-1'/> { translate(11) }
                           </button> */}
                        </div>

                        {!isActive && !ended ?
                           <div className='flex justify-center w-full'>
                              <button
                                 disabled={subStatus?.require_subscription && !subStatus?.user_has_subscription && !subStatus?.subscription_pending_approval || subStatus?.require_subscription && subStatus?.subscription_pending_approval}
                                 className={`flex justify-center items-center border-b-2 ${subStatus?.require_subscription && !subStatus?.user_has_subscription && !subStatus?.subscription_pending_approval || subStatus?.require_subscription && subStatus?.subscription_pending_approval ? "bg-gray-500" : "bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800"} border-white text-white  w-full py-[10px]`}
                                 onClick={() => token ? goToBidSecurity() : navigate('/login')}
                              // onClick = { () => token ? goToAddRecipt() : navigate('/login') }
                              >
                                 {bid_loading ?
                                    <Loading loading={bid_loading} loadingStyle='text-sky-600 w-5 h-5' />
                                    :
                                    translate(30)
                                 }
                              </button>
                              {auctionById?.detail?.required_documents && missingDocuments && missingDocuments?.length > 0 && <button
                                 className={`flex justify-center items-center border-b-2 border-white text-white bg-gradient-to-b from-blue-500 to-sky-700 hover:bg-gradient-to-b hover:sky-blue-600 hover:to-lime-800 w-full py-[10px]`}
                                 onClick={() => token ? (dispatch(toggleUploadDocumentModal({ value: auctionById.detail.id })), dispatch(getRequiredDocumentsList({ value: auctionById.detail.required_documents }))) : navigate('/login')}
                              >
                                 {bid_loading ?
                                    <Loading loading={bid_loading} loadingStyle='text-sky-600 w-5 h-5 ' />
                                    :
                                    "Add Document"
                                 }
                              </button>}
                           </div>
                           :
                           null
                        }

                        {token && startedBidding && !ended ?
                           <div className='flex justify-center w-full'>
                              <button
                                 className='flex justify-center items-center border-b-2 border-white text-white bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px] whitespace-nowrap'
                                 onClick={() => goToBidSubmission(id)}
                              >
                                 {translate(31)}
                              </button>
                           </div>
                           :
                           null
                        }

                        <div className='flex justify-center w-full'>
                           <button
                              className='flex justify-center items-center gap-3 border-white text-white bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px]'
                              onClick={() => location?.state?.cameFrom === 'login' ? navigate(-3) : navigate(-1)}
                           >
                              <BsArrowLeft className='h-5' /> {translate(32)}
                           </button>
                        </div>
                     </div>
                  </div>

                  {processing ?
                     <div className='bg-black/80 h-[88%] w-[18%] rounded-[50%] absolute right-[35%] top-[7%] flex items-center justify-center gap-3'>
                        <Loading min loading={true} loadingStyle='text-white w-7 h-7' /><div className='text-white text-lg font-semibold'>Processing</div>
                     </div>
                     :
                     null
                  }

                  {hitGavel ?
                     <div className='absolute inset-0 left-[10%] bottom-[55%] mx-auto my-auto h-full flex items-center justify-center'>
                        <img src={hit_gavel} alt='' className='w-64 rounded-[50%] object-cover' />
                     </div>
                     :
                     null
                  }

                  {ended ?
                     <div className='absolute inset-0 pt-2 -rotate-[27deg] flex items-center justify-center text-orange-500 font-bold text-4xl h-12  my-auto pb-6 left-[10%]' style={{ textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white' }}>
                        <div className={`${language === 'Afaan-Oromoo' ? 'tracking-[.2em]' : 'tracking-[.9em]'} h-10`}>{translate(33)}</div>
                     </div>
                     :
                     null
                  }

               </div>

               <div className='block lg:hidden pt-2 px-1'>
                  <div className='w-full pr-2'>
                     <div className='flex justify-between'>
                        <p className='text-gray-600 pl-2 text-sm md:text-base'>{translate(3)}</p>
                        <div className={`text-lime-500 ${!detail?.is_closed ? 'text-2xl' : 'text-lg'}`}>
                           {!detail?.is_closed && currentPrice ?
                              <>
                                 {canSeeTopBidder() ?
                                    <div className='text-lg'>{currentPrice.toLocaleString('en', opt)}
                                       <span className='text-sm'> {translate(4)}</span>
                                    </div>
                                    :
                                    <div
                                       title='Click to go to Subscription page'
                                       className='text-sky-800 hover:text-sky-600  cursor-pointer'
                                       onClick={() => navigate('/subscription')}
                                    >
                                       {translate(343)}
                                    </div>
                                 }
                              </>
                              :
                              <div className='text-lg'>{`**${translate(5)}**`}</div>
                           }
                        </div>
                     </div>

                     <div className='flex justify-between'>
                        <p className='text-gray-600 pl-2 text-sm md:text-base'>{translate(6)}</p>
                        {myBidValue ?
                           <div className='text-sky-500 text-lg md:pr-3'>{myBidValue.toLocaleString('en', opt)}<span className='text-sm'> {translate(4)}</span></div>
                           :
                           <div className='text-sky-600 text-lg md:pr-3'>---</div>
                        }
                     </div>

                     <div className='flex justify-between'>
                        <p className='text-gray-600 pl-2 text-sm md:text-base'>{translate(7)}</p>
                        {token ?
                           <p className={`${isActive ? 'text-lime-500' : 'text-red-600'}`}>{isActive ? translate(8) : translate(9)}</p>
                           :
                           <p className='text-gray-600'>{translate(342)}</p>
                        }
                     </div>

                  </div>

                  <div>
                     {isActive ?
                        <div className='flex justify-center pt-3'>
                           <div className='text-xs text-gray-600'>{translate(10)}</div>
                           <GiCheckMark className='text-sm ml-2 text-lime-600' />
                        </div>
                        :
                        null
                     }

                     <div className='grid grid-flow-col auto-cols-[minmax(0,_2fr)] sm:gap-1 px-auto sm:px-1 pt-3'>
                        {token && startedBidding && !ended ?
                           <button
                              className='border-r-2 border-sky-100 text-sm py-2 bg-lime-800 text-white px-2'
                              onClick={() => goToBidSubmission(id)}
                           >
                              {translate(31)}
                           </button>
                           :
                           null
                        }

                        {!isActive && !ended ?
                           <button
                              className='border-r-2 border-sky-100 text-xs py-2 bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 text-white px-2'
                              onClick={() => token ? goToBidSecurity() : navigate('/login')}
                           >
                              {bid_loading ?
                                 <Loading loading={bid_loading} loadingStyle='text-sky-600 w-5 h-5' /> :
                                 translate(30)
                              }
                           </button>
                           :
                           null
                        }

                        <button
                           className={`flex justify-center gap-3 sm:gap-10 text-sm py-2 sm:py-3 bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 text-white px-2`}
                           onClick={() => token ? navigate(`/monitor/${id}`) : navigate('/login')}
                        >
                           <BsFullscreen className='h-5' />
                           {translate(11)}
                        </button>

                        <div className='hidden sm:block'>
                           <button
                              className='flex justify-center items-center gap-10 border-white text-white bg-gradient-to-b from-lime-500 to-lime-700 hover:bg-gradient-to-b hover:from-lime-600 hover:to-lime-800 w-full py-[10px]'
                              onClick={() => location?.state?.cameFrom === 'login' ? navigate(-3) : navigate(-1)}
                           >
                              <BsArrowLeft className='h-5' /> {translate(32)}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='pt-2 sm:pt-4 sm:flex gap-3 sm:w-full px-1'>
                  <div className='w-full sm:w-[40%] flex flex-col gap-3'>
                     {detail?.product?.images?.length ?
                        <div className='gallery_container w-full sm:h-[416px] sm:ml-1' >
                           <ImageGallery
                              items={images}
                              autoPlay={true}
                              showIndex={true}
                              showBullets={false}
                              infinite={true}
                              showThumbnails={true}
                              showFullscreenButton={true}
                              showGalleryFullscreenButton={true}
                              showPlayButton={true}
                              showGalleryPlayButton={true}
                              showNav={true}
                              isRTL={false}
                              slideDuration={450}
                              slideInterval={5000}
                              slideOnThumbnailOver={true}
                              thumbnailPosition={'left'}
                              useWindowKeyDown={true}
                              description={false}
                              additionalClass='image_gallery'
                           />
                        </div>
                        :
                        <div className='w-full h-full sm:w-[40%] sm:h-[416px] relative overflow-hidden rounded-lg'>
                           <img src={image_placeholder} alt='' className='w-full object-cover' />
                           <div className='w-full h-full flex justify-center items-center text-gray-500 text-lg italic md:border border-gray-300 rounded-lg font-thin md:font-medium absolute inset-0'>
                              {translate(34)}
                           </div>
                        </div>
                     }

                     <div className='hidden sm:block ml-1 bg-gray-200 h-[375px] overflow-y-auto'>
                        <div className={`bg-sky-600 flex items-center text-white ${language === 'Amharic' ? 'font-Abyssinica' : 'font-Roboto'} rounded-t-lg pl-3 py-1`}>{translate(338)}</div>
                        {
                           language === 'Amharic'
                              ?
                              <div className='flex-auto text-s p-2 font-Abyssinica leading-4'>
                                 <p>1. አጫራች የተሻለ አማራጭ ካገኘ፣ በጨረታዉ ላለመገደድ እና ጨረታዉን በሙሉም ሆነ በከፊል ለመሰረዝ መብት አለዉ።</p>
                                 <br />
                                 <p>2. ተጨራች የፕላትፎርሙ መስፈርቶች እና በአጫራች የተቀመጡ ቅድመ ሁኔታዎችን ማሟላቱን እዉቅና ይሰጣል።</p>
                                 <br />
                                 <p>3. ተጨራች በኦክሽን ኢትዮጵያ ወይንም በአጫራች በተጠየቀዉ መስፈርት መሰረት፣ የጨረታ ማስከበሪያ የማቅረብ ግዴታ አለበት። ተጫራች የተቀመጡ ግዴታዎችን የማክበር ግዴታ አለበት፣ ይህ ካልሆነ ለጨረታ ማስከበሪያ ያስያዘዉ ገንዘብ ይወረስበታል።</p>
                                 <br />
                                 <p>4. ኦክሽን ኢትዮጵያ የተሰጡ መረጃዎችን እዉነተኝነት በተቻለዉ ያጣራል። ሆኖም ግን ባለቤትነት፣ ትክክለኝነትን፣ ጥራትን፣ እና ማንኛዉንም መረጃ በተመለከተ ዋስትና አይሰጥም። የተሰጡትን መረጃዎች ማጣራት የተጫራች ግዴታ ሲሆን፣ ለተሰጡት መረጃዎች ዋስትና መስጠት ደግሞ የአጫራች/ የባለንብረት ይሆናል።</p>
                                 <br />
                                 <p>5. በጨረታዉ የሚሳተፍ ማንኛዉም ወገን ደንብና ሁኔታዎችን እንዳነበበ እና እንደሚገዛባቸዉ ግምት ይወሰዳል።</p>
                              </div>
                              :
                              language === 'Afaan-Oromoo'
                                 ?
                                 <div className='flex-auto text-sm p-2'>
                                    <p>1. The auctioneer reserves the right to cancel the bid in whole or in part.</p>
                                    <br />
                                    <p>2. The bidder acknowledges that he complies with the requirements of the platform and conditions set by auctioneer.</p>
                                    <br />
                                    <p>3. The bidder must provide bid performance guarantee, in any form of security requested by Auction Ethiopia/the auctioneer. The bidder thereto agrees to comply with the observable obligations, failure to do so will entail the security to be retained.</p>
                                    <br />
                                    <p>4. Auction Ethiopia will take necessary procedures to verify the description given. But, doesn't guarantee the ownership, authenthicity, quality and description of the item. Verifying the information and guarantying the description given on the item will solely remain to be the duty of the bidder and auctioneer respectively. </p>
                                    <br />
                                    <p>5. Any party involved in the bid process is assumed to have read and agreed to abide to the terms and conditions of the platform. </p>
                                 </div>
                                 :
                                 <div className='flex-auto text-sm p-2'>
                                    <p>1. The auctioneer reserves the right to cancel the bid in whole or in part.</p>
                                    <br />
                                    <p>2. The bidder acknowledges that he complies with the requirements of the platform and conditions set by auctioneer.</p>
                                    <br />
                                    <p>3. The bidder must provide bid performance guarantee, in any form of security requested by Auction Ethiopia/the auctioneer. The bidder thereto agrees to comply with the observable obligations, failure to do so will entail the security to be retained.</p>
                                    <br />
                                    <p>4. Auction Ethiopia will take necessary procedures to verify the description given. But, doesn't guarantee the ownership, authenthicity, quality and description of the item. Verifying the information and guarantying the description given on the item will solely remain to be the duty of the bidder and auctioneer respectively. </p>
                                    <br />
                                    <p>5. Any party involved in the bid process is assumed to have read and agreed to abide to the terms and conditions of the platform. </p>
                                 </div>
                        }
                     </div>
                  </div>

                  <div className='w-full sm:w-[34%] sm:ml-1 pt-2 sm:pt-0'>
                     <div className='w-full'>
                        <div className='shadow-sm'>
                           {/* <div className = 'bg-white text-sky-600 pl-2 border border-gray-200 rounded-t-lg'>{ translate(35) }</div> */}
                           <div className='bg-white text-sky-600 pl-2 border border-gray-200 rounded-t-lg'>Description</div>
                           {/* <div className = {`bg-gray-100 text-sm h-auto sm:h-[160px] overflow-y-auto px-2 pt-1 spec`}> */}
                           <div className={`bg-gray-100 text-sm h-auto sm:h-[260px] overflow-y-auto px-2 pt-1 spec`}>
                              {detail?.product?.summary ?
                                 <p className='whitespace-pre-wrap'>
                                    {detail?.product?.summary.split('\r\n').map((word, index) => {
                                       if (word.startsWith('https://') || word.startsWith('http://')) {
                                          return (
                                             <a key={index} href={word} className='text-blue-600 underline' target='_blank' rel='noopener noreferrer'>
                                                {word}
                                             </a>
                                          );
                                       } else {
                                          return <div className='whitespace-pre-wrap' key={index}>{word} </div>;
                                       }
                                    })}
                                 </p>
                                 :
                                 <div className='w-full h-full flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>{translate(36)}</div>
                              }
                           </div>
                           <div className='bg-white text-sky-600 pl-2 border border-gray-200'>መግለጫ</div>
                           {/* <div className = {`bg-gray-100 text-sm h-auto sm:h-[174px] overflow-y-auto px-2 pt-1 spec`}> */}
                           <div className={`bg-gray-100 text-sm h-auto sm:h-[274px] overflow-y-auto px-2 pt-1 spec`}>
                              {detail?.product?.summary_am ?
                                 <p className='whitespace-pre-wrap'>
                                    {detail?.product?.summary_am.split('\r\n').map((word, index) => {
                                       if (word.startsWith('https://') || word.startsWith('http://')) {
                                          return (
                                             <a key={index} href={word} className='text-blue-600 underline' target='_blank' rel='noopener noreferrer'>
                                                {word}
                                             </a>
                                          );
                                       } else {
                                          return <div className='whitespace-pre-wrap' key={index}>{word} </div>;
                                       }
                                    })}
                                 </p>
                                 :
                                 // <div className = 'w-full h-full flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>{ translate(36) }</div>
                                 <div className='w-full h-full flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>-</div>
                              }
                           </div>
                        </div>

                        <div className={`w-full text-sm shadow-sm pt-2`}>
                           <div className='bg-white text-sky-600 pl-2 text-base border border-gray-200 rounded-t-lg w-full'>{translate(37)}</div>
                           {detail?.product?.specs ?

                              // <div className = 'h-auto sm:h-[270px] overflow-y-auto w-full bg-gray-100 spec'>
                              <div className='h-auto sm:h-[70px] overflow-y-auto w-full bg-gray-100 spec'>
                                 {detail?.product?.specs && Object.keys(detail?.product?.specs).map((key, i) => (
                                    <div key={i} className='border-b-2 border-white w-full flex'>
                                       <div className='flex w-1/2'>
                                          <BsCaretRightFill className='pt-1 text-lg text-sky-600' />
                                          <p className='pl-1'>{key}</p>
                                       </div>
                                       <div className='w-1/2'>
                                          {key.endsWith('!') ? (
                                             <div className='flex items-center relative'>
                                                <span>{detail?.product?.specs[key]}</span>
                                                <button
                                                   className='ml-2 focus:outline-none absolute right-3 transition-opacity duration-300 copy-icon'
                                                   title='Copy'
                                                   onClick={(event) => {
                                                      event.stopPropagation();
                                                      copyToClipboard(detail?.product?.specs[key], key);
                                                   }}
                                                >
                                                   {copiedMap[key] ? 'Copied!' : <MdOutlineContentCopy className='text-gray-600 hover:text-gray-800' />}
                                                </button>
                                             </div>
                                          ) : (
                                             <span>{detail?.product?.specs[key]}</span>
                                          )}
                                       </div>
                                    </div>
                                 ))}
                              </div>
                              :
                              // <div className = 'bg-gray-100 w-full h-auto sm:h-[270px] flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>
                              <div className='bg-gray-100 w-full h-auto sm:h-[70px] flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>
                                 {translate(38)}
                              </div>
                           }
                        </div>

                        <div className={`w-full text-sm shadow-sm pt-2`}>
                           <div className='bg-white text-sky-600 pl-2 text-base border border-gray-200 rounded-t-lg'>{translate(39)}</div>
                           <div className='w-full flex gap-8 sm:pt-3 h-auto sm:h-20 bg-gray-100 border-b border-gray-300'>
                              {detail?.product?.documents?.length ?
                                 detail.product.documents.map((doc, idx) => (
                                    <a
                                       key={idx}
                                       href={doc.file}
                                       download={doc.name}
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       <div className='flex items-center justify-center cursor-pointer'>
                                          <img src={pdf_icon} alt={doc.name} className='h-10 sm:h-12' />
                                       </div>
                                       <p className='text-gray-600 font-Roboto text-xs pl-1'>{doc.name ? doc.name : '...................'}</p>
                                    </a>
                                 ))
                                 :
                                 <div className='w-full h-full flex justify-center items-center text-gray-500 text-base md:text-lg italic font-thin'>
                                    {translate(40)}
                                 </div>
                              }
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className='w-full sm:w-[26%] flex flex-col gap-2 pb-'>
                     <div className='w-full mt-3 sm:mt-0'>
                        <div className='font-semibold flex justify-center items-center text-lg text-sky-600 rounded-t-lg bg-white border border-gray-300 relative'>
                           <p>{translate(41)}</p>
                           {detail?.is_hybrid && ended ?
                              <div title='Refresh to see top 10 bidders' className='absolute top-[5px] right-3' onClick={() => dispatch(getTopTenBidders({ id }))}>
                                 <FiRefreshCw className='text-gray-500 text-xl hover:text-sky-600' />
                              </div>
                              :
                              null
                           }
                        </div>
                        {topTenBidders_loading ?
                           <div className='flex justify-center w-full pt-12 h-[403px]'>
                              <Loading loading={topTenBidders_loading} loadingStyle='text-sky-600 w-6 h-6' min />
                           </div>
                           :
                           topBidders.length ?
                              <>
                                 <AnimateTopBidders>
                                    {memoizedBidderBars}
                                 </AnimateTopBidders>

                                 {missedTopBidders.length ?
                                    missedTopBidders.map((m, i) => (
                                       <div key={i} className='w-full'>
                                          <div className={`relative opacity-75 bg-gray-300 text-gray-700 h-[35px] my-[0.3rem] flex justify-between gap-2 rounded-md border border-sky-700 ${m.num <= 3 ? 'font-semibold' : ''}`}>
                                             <span className={`pl-1 sm:pl-2 flex items-center text-gray-700 w-[10%] justify-center text-base ${m.num <= 3 ? 'font-bold' : 'font-[300]'}`}>{m.num}
                                             </span>
                                             <div className={`flex justify-start w-[55%] text-gray-700 items-center h-full truncate italic text-base`}>no bidder</div>
                                          </div>
                                       </div>
                                    )) : null
                                 }
                              </>
                              :
                              defTopTen.map((d, idx) => (
                                 <div key={idx} className='w-full'>
                                    <div className={`relative opacity-75 bg-gray-300 text-gray-700 h-[35px] my-[0.3rem] pr-2 sm:pr-5 flex justify-between gap-2 rounded-md border border-sky-700 ${idx <= 2 ? 'font-semibold' : ''}`}>
                                       {/* <span className = {`pl-1 sm:pl-2 flex items-center text-white w-[10%] justify-center text-base ${idx <= 2 ? 'font-bold' : 'font-[300]'}`}>{!detail?.is_closed ? idx + 1 : ''}</span> */}
                                       <span className={`pl-1 sm:pl-2 flex items-center text-white w-[10%] justify-center text-base ${idx <= 2 ? 'font-bold' : 'font-[300]'}`}>{idx + 1}</span>
                                       <div className={`flex justify-start w-[55%] text-white items-center h-full truncate italic text-base`}>
                                          {!detail?.is_closed ? 'no bidder' : ended ? 'processing' : 'hidden'}
                                       </div>
                                    </div>
                                 </div>
                              ))
                        }
                     </div>

                     <div className='w-full h-40 sm:h-[362px] bg-lime-100 relative' onClick={() => setShowLocation(true)} onMouseOver={() => setShowMapTooltip(true)} onMouseOut={() => setShowMapTooltip(false)}>
                        <img src={map} alt='' className='object-cover w-full sm:w-auto sm:h-full' />
                        {showMapTooltip ?
                           <div className='w-full flex justify-center absolute left-0 top-10 right-0 cursor-pointer'>
                              <div onClick={() => setShowLocation(true)} className='hidden md:block items-center justify-center py-auto h-[70px] rounded-xl px-2 py-2 border border-gray-400 bg-black/70 hover:bg-black/90 text-white w-[96%] text-base sm:text-lg lg:text-xl'>
                                 <p className='text-center'>{translate(344)}</p>
                              </div>
                           </div>
                           :
                           null
                        }
                        <div className='w-full flex justify-center absolute left-0 top-[70%] sm:[10%] right-0 cursor-pointer'>
                           <button onClick={() => setShowLocation(true)} className='block md:hidden py-auto rounded-xl px-2 py-2 border border-gray-400 bg-black/70 hover:bg-black/90 text-white w-[80%] text-base'>
                              <p className='text-center'>{translate(345)}</p>
                           </button>
                        </div>
                     </div>

                     {showLocation ?
                        <Modal visible={showLocation} customWidth='h-[400px] md:h-[600px] w-[90%] md:w-[80vw] md:mb-10'>
                           <div className='h-full w-full'>
                              <div className='flex justify-between bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 rounded-t-lg py-1'>
                                 <div className={`text-base lg:text-lg text-white ${language === 'Amharic' ? 'font-Abyssinica' : 'font-Roboto'} pl-3`}>{translate(43)}</div>
                                 <div className='text-gray-700 text-xl md:text-2xl pr-2 cursor-pointer flex items-center' onClick={() => setShowLocation(false)}>
                                    <BsXLg className='hover:text-red-600' />
                                 </div>
                              </div>
                              <Mapping
                                 center={detail?.product?.lat && detail?.product?.lng ? { lat: detail.product.lat, lng: detail.product.lng } : center}
                                 img={detail?.product?.thumbnail ? detail.product.thumbnail : image_placeholder}
                                 heading={detail?.product?.name}
                                 description={detail?.product?.summary.substring(0, 50) + '...'}
                                 tooltipTitle={translate(43)}
                              />
                           </div>
                        </Modal>
                        :
                        null
                     }
                     <Modal visible={show_compliance_modal} customWidth='w-[84%] md:w-[30%]' >
                        <div className='flex justify-between items-center  bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg'>
                           <div className='text-center text-white text-lg font-semibold pl-4 md:pl-8'>Add New Compliance</div>
                           {/* <div className='pr-4 pt-1'><Loading  loadingStyle='text-sky-600 w-5 h-5' /></div> */}
                           <FiX
                              className='text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500 mr-5'
                              onClick={() => {

                                 dispatch(toggleComplianceModal({ value: false }))
                              }}
                           />

                        </div>
                        <div className='w-full py-7 px-4 md:px-8'>

                           <div className='w-full sm:w-3/5'>
                              <div className='w-full '>
                                 <label htmlFor='message' className='block mb-1 text-[0.7rem] font-medium text-gray-400'>Message</label>
                                 <textarea
                            
                                    value={compliance.message}
                                    onChange={(e) => setCompliance({ ...compliance, message: e.target.value })}
                                    type='text'
                                    id='message'
                                    className='block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none'
                                    placeholder={"message"}
                                 />
                              </div>


                           </div>






                           <div className='flex justify-center w-full pt-7'>
                              <div className='flex justify-between w-full md:w-full'>
                                 <button
                                    className='bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                                    onClick={() => {
                                       dispatch(toggleComplianceModal({ value: false }))

                                    }}
                                 >
                                    {translate(63)}
                                 </button>
                                 <button
                                    className={` bg-lime-600 hover:bg-lime-700 text-white rounded w-[40%] py-2`}


                                    onClick={() => { SubmitCompliance() }}
                                 >
                                    {translate(175)}
                                 </button>
                              </div>
                           </div>
                        </div>
                     </Modal>
                  </div>
               </div>
            </div>
            :
            <div className='flex justify-center w-full pt-12'>
               <Loading loading={auctionById_loading} loadingStyle='text-sky-600 w-7 h-7' />
            </div>
         }
      </>
   )
}

export default AuctionDetail;