import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CountdownTimer, getCharLength, opt, Pagination } from '../../utils';
import { hit_gavel, image_placeholder, sealed_icon, top_bidder_cup_1, unwatch_icon, watch_icon, zig_chereta } from '../../assets';
import dayjs from 'dayjs';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';
import Loading from '../common/Loading';


const AuctionGrid = ({ goToBidSubmission, selectedAuct, isWatched, handleWatchClick, showNotifierPopup, auctionStatuses, currentPriceBg, canSeeTopBidder }) => { 

	const navigate = useNavigate();
	const token = AuthService.getToken();

	const { language } = useSelector(({ language }) => language);
	const { watchlist_loading } = useSelector(({ watchList }) => watchList);
	const { auctions, auctionIsWatchlist, auction_loading2, auctionById_loading, updatedBidId, keyword, nowUtcTime, newBidder } = useSelector(({ auction }) => auction);

	const [selectedAuction, setSelectedAuction] = useState(0);

	

	return (
		<>
			{auctions.length && auctionStatuses.length ?
				<div className = {`px-1 sm:px-4 ${auctionIsWatchlist ? 'pt-2' : ''}`}>
					<div className = 'sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-2 md:px-0 mb-4'>
						{
							auctionStatuses.map(({ auction, current_price, startedBidding, isTopBidder, isAuctionEndingSoon, processing, hitGavel, ended  }) => (
								<div key = { auction.id }  className = 'text-center mt-2 md:mt-0 rounded-lg shadow-lg shadow-stone-400 border border-stone-400 md:mb-0 sm:hover:scale-105 duration-200 relative'>
									<div className = {`${ended ? 'bg-black/10' : 'bg-white'} text-gray-600 h-72 flex-col flex justify-center pt-2 rounded-lg`}>
										<div className = 'border-gray-400 px-2 flex relative h-[32%]'>
											<div className = 'pt-1'>
												<div className = 'w-28 h-[67.2px] flex flex-col bg-black/50'>
													<img 
														className = 'w-full h-full object-contain rounded cursor-pointer' 
														src = { auction?.product?.thumbnail ? auction.product.thumbnail : image_placeholder } 
														alt = { auction?.product?.name ? auction.product.name : '--' } 
														onClick = { () => navigate(`/auction/${auction.id}`) }
													/>
												</div>
											</div>

											<div className = 'w-[55%] flex flex-col gap-1 justify-between pb-2 pl-2 relative'>
												<p 
													className = 'text-base text-left hover:text-sky-600 text-black font-Roboto flex-none cursor-pointer'
													onClick = { () => navigate(`/auction/${auction.id}`) } 
												>
													{ auction?.product?.name }
												</p>
												<p className = {`text-xs text-start overflow-y-auto flex-grow ${ended ? 'summary-ended' : 'summary'}`}>
													{auction?.product?.summary ?
														<>
															<span className = ''>{auction.product.summary.slice(0, getCharLength()) + ' ... '}</span>
															<span onClick = { () => navigate(`/auction/${auction.id}`) } className = ' text-sky-600 cursor-pointer hover:text-sky-700 pl-1'>
																{ translate(50) }
															</span>
														</> 
														:
														<span>---</span>
													}
												</p>

												{current_price && !auction?.is_closed ?
													<div className = {`absolute top-0 right-0 left-0 h-auto sm:h-14 flex items-center sm:justify-center ${showNotifierPopup && (updatedBidId === auction?.id) ? 'show-notifier-popup' : 'hide-notifier-popup'}`}>
														<div className = 'flex text-white h-full rounded-lg w-[71%] sm:w-[90%] justify-center items-center notifier-popup px-3 bg-sky-800'>
															<div>
																{ translate(266) } 
																<span className = 'text-base sm:text-lg font-bold'> {current_price && current_price?.toLocaleString('en', opt)} </span>
																{ translate(267) }
															</div> 
														</div>
													</div>
													: 
													null
												}
												
											</div>
											
											{isTopBidder && !auction?.is_closed ?
												<div 
													title = {`${!ended ? translate(12) : translate(13)}`} 
													className = {`absolute ${ended ? 'right-1' : language === 'English' ? 'right-7' : language === 'Amharic' ? 'right-5' : 'right-2'} -inset-y-1 `}>
														<div className = 'flex justify-center'><img src= { top_bidder_cup_1 } alt = '' className = {` ${!ended ? 'h-10 py-1 top-bidder': 'h-10'} `}/></div>
														<p className = {`${!ended ? 'text-yellow-600': 'text-lime-700'} text-xs`}>
															{ !ended ? translate(45) : translate(46) }
														</p>
												</div>
												: 
												null
											}

											{isAuctionEndingSoon ?
												<div 
													title = { translate(29) } 
													className = { `blob absolute right-1` }>
												</div> 
												: 
												null
											}
											
										</div>

										<div className = 'h-[9%] flex justify-evenly items-center pb-1 border-b border-gray-400'>
											<div className = 'text-xs'>{translate(17)} &nbsp; - &nbsp; 
												<span className = 'text-base font-semibold font-Roboto'> { auction.no_of_bids }</span>
											</div>
											<div className = 'text-sky-600'>|</div>
											<div className = 'text-xs'>{translate(18)} &nbsp; - &nbsp; 
												<span className = 'text-base font-semibold font-Roboto'> { auction.lot_number }</span>
											</div>
										</div>

										{auction?.is_closed ?
										
											// <div className = 'border-b border-gray-400 flex h-[30%] items-center justify-center font-semibold gap-3'>
											// 	<img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
											// 	<div className = 'text-red-400 text-lg'>{translate(14)}</div>
											// </div>

											<div className = 'border-b border-gray-400 flex flex-col h-[30%] items-center justify-between'>
												<div className = 'flex items-center justify-center font-semibold gap-3'>
													<img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
													<div className = 'text-red-400 text-lg'>{translate(14)}</div>
												</div>

												<div className = 'flex flex-col justify-between border-t border-gray-400 w-full'>
													<p className = 'text-xs text-left pl-2 truncate'>{ translate(19) }</p>
													<p className = 'text-sm'>
														<span className = 'text-lg font-semibold font-Roboto'>{ auction.initial_price.toLocaleString('en', opt) }</span>
														{ translate(4) }
													</p>
												</div>
											</div>
											
											:
											<>
												<div className = 'border-b border-gray-400 flex h-[15%]'>
													<div className = {`border-r  flex flex-col justify-between w-[60%] ${ newBidder && (updatedBidId === auction?.id) ? currentPriceBg : 'bg-fourth-50 text-white border-gray-400'}`}>
														<p className = 'text-xs text-left text-white pl-2 truncate'>{ translate(3) }</p>

														{canSeeTopBidder() ?
															<p className = 'text-sm text-white '>
																<span className = 'text-lg font-semibold font-Roboto'>{ current_price && current_price.toLocaleString('en', opt) }</span>
																{ translate(4) }
															</p>
															:
															<p className = 'text-base font-semibold text-green-300 hover:text-green-400 cursor-pointer'
																title = 'Click to go to Subscription page' 
																onClick = { () => navigate('/subscription')}>
																{ translate(343) }
															</p>
														}

													</div>
													<div className = 'flex flex-col justify-between w-[40%]'>
														<p className = 'text-xs text-left pl-2'>{ translate(15) }</p>
														<p className = 'text-sm'>
															<span className = 'text-lg font-semibold'> { auction.min_increment.toLocaleString('en', opt) }</span>
															{ translate(4) }
														</p>
													</div>
												</div>

												<div className = 'border-b border-gray-400 flex h-[15%]'>
													<div className = 'flex flex-col justify-between border-r border-gray-400 w-[60%]'>
														<p className = 'text-xs text-left pl-2 truncate'>{ translate(19) }</p>
														<p className = 'text-sm'>
															<span className = 'text-lg font-semibold font-Roboto'>{ auction.initial_price.toLocaleString('en', opt) }</span>
															{ translate(4) }
														</p>
													</div>
													<div className = 'flex flex-col justify-between w-[40%]'>
														<p className = 'text-xs text-left pl-2 truncate'>{ translate(16) }</p>
														
														<p className = 'text-sm truncate px-1'>
															<span className = {`text-lg ${language === 'Amharic' && !auction.max_increment ? 'font-normal' : 'font-semibold font-Roboto'}`}>
																{ auction.max_increment ? auction.max_increment.toLocaleString('en', opt) : translate(81) } 
															</span> 
															{ auction.max_increment ? translate(4) : null }
														</p>
													</div>
												</div>
											</>
										}

										<div className = 'border-b border-gray-400 flex h-[15%]'>
												<div className = 'border-r border-gray-400 flex flex-col justify-between w-[47%]'>
													<p className = 'text-xs text-left pl-2 cursor-pointer'>{ translate(20) }</p>
													<div className = 'text-center font-Roboto'>
													<CountdownTimer 
														timestampMs = { new Date(auction?.end_time).getTime() } 
														view = 'grid'
													/>
													</div>
												</div>
												<div className = 'flex flex-col justify-between w-[53%]'>
													<p className = 'text-xs text-left pl-2'>{ translate(25) }</p>
													<p className = 'text-sm font-semibold font-Roboto'>{ dayjs(new Date(auction.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
												</div>
										</div>

										<div className = 'flex h-[14%]'>
												
											<div className = 'border-r border-gray-400 flex justify-center items-center w-[12%] bg-gray-200 hover:bg-white rounded-bl-lg'>
												{
													!auctionIsWatchlist
													?
													<>
														{
															watchlist_loading && selectedAuction === auction?.id
															?
															<Loading loading = { watchlist_loading } loadingStyle = 'text-sky-600 w-5 h-5'/>
															:
															<img 
																src = { (token && isWatched(auction?.id)) ? unwatch_icon : watch_icon } 
																alt = 'watchlist'  
																title = { (token && isWatched(auction?.id)) ? translate(27) : translate(26) }
																className = 'w-8 md:w-9 cursor-pointer'       
																onClick = { () => { setSelectedAuction(auction?.id); handleWatchClick(auction?.id) } }
															/> 
														}
													</>
													: 
													<>
														{(selectedAuction === auction.id) && (watchlist_loading || auction_loading2) ?
															<Loading loading = { auction_loading2 || watchlist_loading} loadingStyle = 'text-sky-600 w-5 h-5'/>
															: 
															<img 
																src = { unwatch_icon }
																alt = 'watchlist' 
																title = { translate(27) } 
																className = 'w-8 md:w-9 cursor-pointer' 
																onClick = { () => { setSelectedAuction(auction.id); handleWatchClick(auction.id) } }
															/> 
														}
													</>
												}
											</div>

											<div className = 'flex justify-center items-center border-r border-gray-400 p-1 w-[35%]'>
												<button 
													className = 'bg-sky-600 w-full h-full text-white text-xs hover:bg-lime-600' 
													onClick = { () => navigate(`/auction/${auction.id}`) } 
												>
													{ translate(37) }
												</button>
											</div>

											<div className = 'flex items-center justify-center p-1 w-[53%]'>
												<button 
													className = {`rounded-br w-full h-full text-xs 
														${(new Date(auction.end_time).getTime() > nowUtcTime) ? 
														'bg-sky-600 hover:bg-lime-600 text-white' : 
														'bg-gray-300 text-gray-400 cursor-not-allowed'}`
													}
													disabled = { new Date(auction.end_time).getTime() < nowUtcTime }
													onClick = { () =>{
														console.log("wwwwwwww",auction.id)
													 navigate(`/auction/${auction.id}`) } }
														
														
														// goToBidSubmission(auction.id,auction)} }
												>
													{auctionById_loading && selectedAuct === auction.id ? 
														<Loading loading = { auctionById_loading } loadingStyle = 'text-white w-5 h-5'/>
														:
														startedBidding ? translate(31) : translate(28)
													}
												</button>
											</div>
										</div>
									</div>
									
									{processing ?
										<div className = 'bg-black/80 h-[35%] w-[60%] rounded-[50%] absolute right-[20%] top-[30%] flex items-center justify-center gap-3'>
											<Loading min loading={true} loadingStyle= 'text-white w-7 h-7' />
											<div className = 'text-white text-lg font-semibold'>Processing</div>
										</div>
										: 
										null
									}

									{hitGavel ? 
										<div className = 'absolute inset-0 bottom-[15%] mx-auto my-auto w-72 flex items-center justify-center'>
											<img src = { hit_gavel } alt = '' className = 'w-72 object-cover' />
										</div>
										: 
										null
									}

									{ended ?
										<div className = 'absolute inset-0 -rotate-[35deg] flex items-center justify-center text-orange-500 font-bold text-4xl h-12 w-64 mx-auto my-auto pb-6 pl-8' style = {{textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'}}>
											<div className = {`${language === 'Afaan-Oromoo' ? 'tracking-[.6em]' : 'tracking-[.8em]'} h-10`}>{ translate(33) }</div>
										</div>
										: 
										null
									}

								</div>
							))
						}
					</div>

					<div className = {`w-full flex justify-end ${auctions.length <= 8 || auctionIsWatchlist ? 'block md:hidden' : ''}`}>
						<Pagination />
					</div>
					
				</div> 
				: 
				<div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>
					{auctionIsWatchlist ? 
						translate(47) : 
						keyword ? 
						translate(48):
						translate(49) 
					} 
				</div>
			}
		</>
	)
}

export default AuctionGrid;
