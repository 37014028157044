import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, sendMessages, setFirstLoad } from '../../features/chatSlice';
import { MdSend } from 'react-icons/md';
import { user_man, user_woman } from '../../assets';
import Loading from '../common/Loading';
import dayjs from 'dayjs';
import translate from '../../Services/translate';
import myModel from '../../Services/api';


const Compliance = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { chats, chat_loading, send_loading } = useSelector(({ chat }) => chat);
  const { myProfile } = useSelector(({ auth }) => auth);

  const [msg, setMsg] = useState({ message: '' });
  const [loading, setLoading]=useState(true)
  const [complianceList, setcomplianceList]=useState(null)

  const msg_input = useRef(null)

  const sendMsg = e => {
    e.preventDefault()
    dispatch(sendMessages(msg)).then(() => {
      dispatch(setFirstLoad({ firstLoad: false }))
      setMsg({ message: '' })
      dispatch(getMessages())
    })
  }

  useEffect(() => {
    dispatch(setFirstLoad({ firstLoad: true }))
    dispatch(getMessages())
    // eslint-disable-next-line
  }, [])

 
  
  const fetchCompliance = async () => {
    setLoading(true)

    try {
       const response = await myModel.find('/compliance-list')
       console.log("kkkllll", response)
       if (response) {
        setLoading(false)
        setcomplianceList(response)
       }
       return response
    } catch (error) {
        setLoading(false)

       console.log(error)
       // return rejectWithValue(error)
    }


 };
useEffect(()=>{
    fetchCompliance()
},[])

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    };

   return (
      <div className = 'relative'>
         <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl'>Compliance</h2>
         </div>


{loading?<div>loading</div>:<div className='p-5'>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-20">
        {complianceList.map((item) => (
          <div
            key={item.id}
            className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow"
          >
            <div className="mb-2">
              <span className="font-semibold text-gray-700">Auction ID:</span>{" "}
              <span className="text-gray-900">{item.auction}</span>
            </div>
            <div className="mb-2">
              <span className="font-semibold text-gray-700">Message:</span>{" "}
              <span className="text-gray-900">{item.message}</span>
            </div>
            <div className="mb-2">
              <span className="font-semibold text-gray-700">Status:</span>{" "}
              <span
                className={`text-sm font-medium ${
                  item.status === "Pending" ? "text-red-600" : "text-green-600"
                }`}
              >
                {item.status}
              </span>
            </div>
            <div>
              <span className="font-semibold text-gray-700">Created At:</span>{" "}
              <span className="text-gray-900">{formatDate(item.created_at)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>}
         {/* {!loading ?
            <div className = 'chat-thread'>
               {chats.length ?
                  <>
                  {chats.map((chat, i) => (
                     <div key = {i} className = 'flex flex-col'>
                        {chat.sender === myProfile?.user_profile?.username &&
                        <> 
                           <div>
                              <div  className = 'flex justify-end'>
                              <div className = ''>
                                 <div className = {`msg me`}>
                                    { chat.message }
                                 </div>
                              </div>
                              
                              <div className = 'h-11 w-11 rounded full'>
                                 <img 
                                    src = {myProfile?.user_profile?.image_url ? 
                                    `${myProfile.user_profile.image_url}` : 
                                    myProfile?.user_profile?.gender === 'FEMALE' ? user_woman : 
                                    user_man} alt = 'user' className = 'h-full w-full rounded-full object-cover'
                                 />
                              </div>
                              </div>
                           </div>
                           <p className = 'text-xs text-right text-stone-300 pb-7 pr-24 -mt-5'>{ dayjs(chat.timestamp).format('MMM D, YYYY') }</p> 
                        </>}
                        {chat.sender === 'yamlak' &&
                        <>
                           <div className = ''>
                              <div className = 'msg friend'>{ chat.message }</div>
                           </div>
                           <p className = 'text-xs pl-24 text-stone-300 pb-7 flex items-start -mt-5'>{ dayjs(chat.timestamp).format('MMM D, YYYY') }</p>
                        </>
                        }
                     </div>
                  ))
                  }
                  </>
                  :
                  <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>
                     {translate(123)}
                  </div>
               }
            </div>
            :
            <div className = 'flex justify-center w-full pt-12'>
               <Loading loading = { "no " } loadingStyle = 'text-sky-600 w-7 h-7'/>
            </div>
         } */}

     
      </div>
   )
}

export default Compliance;