import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarRoutes } from '../../routes/routes';
import { user_icon } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { toggleChangePasswordModal } from '../../features/authSlice';
import { MdDeleteOutline, MdLogout, MdGavel, MdOutlineFileCopy, MdOutlineKeyboardArrowDown, MdOutlineClose, MdMenu} from 'react-icons/md';
import { BsChat, BsTag, BsKey, BsCurrencyDollar } from 'react-icons/bs';
import { SlUser } from 'react-icons/sl';
import SubMenu from './SubMenu';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';


const Sidebar = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = AuthService.getToken();
  const { myProfile } = useSelector(({ auth }) => auth);
  const [sidebar, setSidebar] = useState(false);
  const [showDP, setShowDP] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);


   return (
      <>
         <div className = 'burger block lg:hidden'>
            <div className = 'nav-link' onClick = {() => { showSidebar(); setShowDP(false); }}>
               <MdMenu className = 'text-gray-700 text-4xl mr-3'/>
            </div>
         </div>

         <div className = { `side-bar-nav ${sidebar ? 'side-bar' : 'not-side-bar'}` }>
            <div className = 'w-full'>
               <div className = 'flex justify-between nav-link pr-1 bg-primary-100 -ml-40 mb-4' >

                  <div className = { `flex items-center pl-2 text-left h-full w-[53%] ${!auth ? 'hidden' : ''}` }>
                     <div className = 'flex text-base items-center relative group'>
                        <div className = 'w-10 h-10 flex items-center cursor-pointer' onClick = {() => { navigate('/myAccount'); setShowDP(false); showSidebar()}}>

                           <img
                              className = 'h-10 w-10 object-cover shadow-sm rounded-full object-top'
                              src = { myProfile?.user_profile?.image_url ? `${myProfile.user_profile.image_url}` : user_icon }
                              alt = 'user' 
                           />

                        </div>
                        <div className = ''>
                           <div className = 'flex cursor-pointer borde border-white pr-3' onClick = { () => setShowDP(!showDP)}>
                              <div className = {`text-white w-36 truncate pl-2`}>{ auth ? auth.user : '' }</div>
                              <MdOutlineKeyboardArrowDown className = 'text-3xl text-white'/>
                           </div>

                           <div className = {`top-12 right-0 left-0 absolute ${showDP ? 'block' : 'hidden'} rounded-b-lg overflow-hidden`}>
                              <div className = 'bg-black/70 backdrop-blur-sm  rounded-b text-xs md:text-sm shadow-md shadow-stone-400' onBlur = {() => setShowDP(false)}>
                                 <div className = 'grid grid-cols-1 gap-0 whitespace-nowrap text-white pl-1 cursor-pointer'>
                                    <div 
                                       onClick = { () => { navigate('/myAccount'); showSidebar(); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1 text-sky-200'><SlUser className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(146) }</p> 
                                    </div>
                                    <div 
                                       onClick = { () => { navigate('/my-bids'); showSidebar(); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1 text-sky-200'><MdGavel className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(129) }</p> 
                                    </div>
                                    <div 
                                       onClick = { () => { navigate('/my-purchases'); showSidebar(); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1 text-sky-200'><BsTag className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(130) }</p> 
                                    </div>
                                    <div 
                                       onClick = { () => { navigate('/my-balance'); showSidebar(); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1 text-sky-200'><BsCurrencyDollar className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(131) }</p> 
                                    </div>
                                    <div 
                                       onClick = { () => { navigate('/my-documents'); showSidebar(); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1 text-sky-200'><MdOutlineFileCopy className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(132) }</p> 
                                    </div>
                                    <div 
                                       onClick = { () => { showSidebar(); dispatch(toggleChangePasswordModal({ value: true })); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1'><BsKey className = 'text-lg text-sky-200'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(148) }</p>
                                    </div>
                                    <div 
                                       onClick = { () => { showSidebar(); navigate('/chatting'); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 flex items-center'>
                                       <div className = 'mr-1'><BsChat className = 'text-lg text-sky-200'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(149) }</p>
                                    </div>
                                  
                                    <div 
                                       onClick = { () => { showSidebar(); navigate('/delete-account'); setShowDP(false) } } 
                                       className = 'px-1 hover:bg-gray-100 py-1 border-b border-gray-400 font-semibold text-red-600 flex items-center'>
                                       <div className = 'mr-1'><MdDeleteOutline className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(76) }</p>
                                    </div>
                                    <div 
                                       onClick = { () => { showSidebar(); navigate('/logout'); ; setShowDP(false)} } 
                                       className = 'px-1 hover:bg-gray-100 hover:text-sky-600 py-3 border-b border-gray-400 font-semibold text-sky-200 flex items-center'>
                                       <div className = 'mr-1'><MdLogout className = 'text-lg'/></div>
                                       <p className = 'pl-2 text-sm'>{ translate(150) }</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <MdOutlineClose className = 'text-white text-3xl' onClick = { () => { showSidebar(); setShowDP(false); } }/>

               </div>

               {SidebarRoutes.map((item, index) => <SubMenu item = { item } key = { index } showSidebar = { showSidebar } />)}

            </div>
         </div>
      </>
   );
};

export default Sidebar;